<template>
  <div class="bg-white min-h-screen flex flex-col justify-center items-center px-4">
    <div class="h-96 w-full sm:max-w-full md:max-w-md flex flex-col gap-4 items-center content-start">
      <div>
        <picture>
          <source srcset="../../../assets/images/asoms-logo.webp" type="image/webp" />
          <img
            class="AsomLogin__Logo"
            src="../../../assets/images/asoms-logo.png"
            alt="ASOMS"
          />
        </picture>
      </div>
      <div class="prose text-center" v-if="!isValidMobileAccessKey">
        <h3>Invalid Access</h3>
        <p>This service can only be access from <br/>ASOMS Lite mobile app.</p>
      </div>
      <slot v-else></slot>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";

export default {
  computed: {
    isValidMobileAccessKey() {
      const key = get(this.$route, 'query.access-key');
      return key === process.env.VUE_APP_MOBILE_ACCESS_KEY;
    }
  }
}
</script>

<style>

</style>