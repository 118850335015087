<template>
  <login-mobile-layout>
    <div class="w-full text-center" v-if="isLoggingOut">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div class="prose text-center" v-else-if="success">
      <h3>User is logged out</h3>
      <p>Redirecting back to ASOM Lite. Please wait for a moment.</p>
    </div>
    <template v-else>
      <div class="prose text-center">
        <h3>Failed to log out</h3>
        <p v-if="is401">Invalid Token</p>
        <p v-else>This action cannot be completed at the moment. Please retry after a few minutes, or contact administrators for support</p>
      </div>
      <asom-button size="full" text="Back to ASOMS Lite" @click="logoutFailureRedirect" />
    </template>

  </login-mobile-layout>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { getAsync } from '@/services/serviceHandlers';
import { AUTH_LOGOUT, MOBILE_LOGOUT_FAILURE, MOBILE_LOGOUT_SUCCESS } from '@/constants/apis';
import LoginMobileLayout from "./_LoginMobileLayout.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    LoginMobileLayout
  },
  data() {
    return {
      isLoggingOut: true,
      error: null,
      success: false,
      status: 0,
    }
  },
  computed: {
    ...mapGetters({
      webToken: 'auth/token',
    }),
    token() {
      return get(this.$route, 'query.token');
    },
    hasToken() {
      return !isEmpty(this.token)
    },
    isSameTokenAsWeb() {
      return this.hasToken && this.token === this.webToken;
    },
    is401() {
      return this.status === 401 || this.status === '401';
    }
  },
  mounted() {
    this.logOutAsync();
  },
  methods: {
    async logOutAsync() {
      this.isLoggingOut = true;
      let result = null;
      if (this.isSameTokenAsWeb) {
        result = await this.$store.dispatch("logout");
        if (result.success) {
          this.$store.dispatch("clearData");
        }
      } else {
        result = await getAsync({
          url: AUTH_LOGOUT,
          options: {
            headers: { "Authorization": `bearer ${this.token}` }
          }
        });
      }
      
      if (result && result.success) {
        this.success = true;
        this.logoutSuccessRedirect();
      } else {
        this.status = get(result, 'status');
        this.error = result.payload;
      }
      this.isLoggingOut = false;
    },
    logoutSuccessRedirect() {
      setTimeout(function() {
        window.location.replace(MOBILE_LOGOUT_SUCCESS); 
      }, 3000);
    },
    logoutFailureRedirect() {
      window.location.replace(MOBILE_LOGOUT_FAILURE); 
    }
  }
}
</script>

<style>

</style>