<template>
  <login-mobile-layout>
    <div class="w-full text-center" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else>
      <asom-alert class="w-full" v-if="error" variant="error" :error-message="error" />
      <asom-form-field
        :label="$t('screens.auth.Login.form.userName.label')"
        class="w-full"
      >
        <asom-input-text
          :placeholder="$t('screens.auth.Login.form.userName.placeholder')"
          v-model="userName"
          :state="inputStates('userName')"
        />
      </asom-form-field>
      <asom-form-field
        :label="$t('screens.auth.Login.form.password.label')"
        class="w-full"
      >
        <asom-input-text
          :placeholder="$t('screens.auth.Login.form.password.placeholder')"
          type="password"
          v-model="password"
          :state="inputStates('password')"
        />
      </asom-form-field>
      <asom-button text="Login" size="full" @click="onSubmit" />
      <asom-button outline variant="secondary" text="Cancel" size="full" @click="onCancel" />
    </template>
  </login-mobile-layout>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import LoginMobileLayout from "./_LoginMobileLayout.vue";
import { MOBILE_LOGIN_FAILURE } from '../../../constants/apis';
import { generateMobileOTP } from "../../../services/auth.service";

export default {
  components: {
    LoginMobileLayout,
  },
  mixins: [inputStates],
  data() {
    return {
      isLoading: false,
      userName: "",
      password: "",
      isLoggingIn: false,
      error: "",
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: `auth/isAuthenticated`,
      officerId: 'auth/userId',
      token: 'auth/token',
    }),
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      userName: { required },
      password: { required },
    };
  },
  mounted() {
    if (this.v$) {
      this.v$.$reset();
    }
    if (this.isLoggedIn) {
      this.getOTPAsync();
    }
  },
  methods: {
    async onSubmit() {
      if (this.isLoggingIn) return;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isLoggingIn = true;
        
        const loginResult = await this.$store.dispatch("login", {
          userName: this.userName,
          password: this.password,
          accessKey: process.env.VUE_APP_ACCESS_KEY,
        });

        this.isLoggingIn = false;
        if (!loginResult || !loginResult.success) {
          this.error = loginResult.payload;
          return;
        }

        await this.getOTPAsync();
      } else {
        this.error = "Username and Password are required.";
      }
    },
    async getOTPAsync() {
      this.isLoading = true;
      const generateOTPResult = await generateMobileOTP();
      const is401 = generateOTPResult.status === 401;
      if (!generateOTPResult || !generateOTPResult.success) {
        this.isLoading = false;
        if (is401)
          this.onCancel();
        else {
          this.error = generateOTPResult.payload || 'Session expired';
        }
      } else {
        const otp = get(generateOTPResult, 'payload.otp', "");
        this.redirectToSuccessPage(otp);
      }
    },
    redirectToSuccessPage(otp) {
      this.$router.push({ name: "Login Mobile Success", query: {
        'access-key': process.env.VUE_APP_MOBILE_ACCESS_KEY,
        otp: otp,
      }});
    },
    onCancel() {
      window.location.replace(MOBILE_LOGIN_FAILURE);
    }
  },
};
</script>

<style>

</style>
