<template>
  <login-mobile-layout>
    <div class="prose text-center">
      <h3>{{hasParams ? 'Login Success' : 'Login Failed' }}</h3>
      <p>Click on button below to redirect back to mobile application</p>
    </div>
    <asom-button text="Back to ASOMS Lite" size="full" @click="onClick" />
  </login-mobile-layout>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import LoginMobileLayout from "./_LoginMobileLayout.vue";
import { MOBILE_LOGIN_FAILURE, MOBILE_LOGIN_SUCCESS } from '../../../constants/apis';

export default {
  components: {
    LoginMobileLayout,
  },
  computed: {
    otp() {
      return get(this.$route, 'query.otp');
    },
    hasParams() {
      return !isEmpty(this.otp);
    }
  },
  methods: {
    onClick() {
      if (this.hasParams) {
        window.location.replace(MOBILE_LOGIN_SUCCESS(this.otp));  
      } else {
        window.location.replace(MOBILE_LOGIN_FAILURE);
      }
    }
  }
}
</script>

<style>

</style>
