<template>
  <asom-container>
    <div class="text-left w-full my-4 space-y-4">
      <asom-alert v-if="loginError" variant="error" :error-message="loginError" />
      <asom-form-field label="Password" required class="w-full md:w-1/2" description="Default password is required">
        <asom-input-text
          type="password"
          v-model="password"
        />
      </asom-form-field>
      <h1 class="text-3xl font-bold">User list for testing purpose</h1>
      <asom-server-table
        :url="url"
        :columns="['no', 'personNo', 'name', 'username', 'line', 'station', 'roles', 'action']"
      >
        <template v-slot:no="scopedSlots">
          {{ scopedSlots.rowIndex + 1 }}
        </template>
        <template v-slot:roles="scopedSlots">
          {{
            scopedSlots.rowData.roles && scopedSlots.rowData.roles.join(", ")
          }}
        </template>
        
        <template v-slot:action="scopedSlots">
          <asom-button
            @click="loginUser(scopedSlots.rowData)"
            text="Login"
            :disabled="!!loginId"
          />
        </template>
      </asom-server-table>
    </div>
  </asom-container>
</template>
<script>
import isEmpty from "lodash.isempty";
import { AUTH_API_HOST } from "@/constants/apis";

export default {
  name: "TestLogin",
  data() {
    return {
      loginId: null,
      loginError: "",
      password: "",
    };
  },
  computed: {
    url() {
      return AUTH_API_HOST + "/auth/test-logins?AccessKey=" + process.env.VUE_APP_ACCESS_KEY
;
    }
  },
  methods: {
    async loginUser(user) {
      if (this.loginId === null) {
        if (isEmpty(this.password)) {
          this.loginError = "Password is required";
          return;
        }

        this.loginId = user.id;
        this.loginError = "";
        
        const loginResult = await this.$store.dispatch("login", {
          userName: user.username,
          password: this.password,
          accessKey: process.env.VUE_APP_ACCESS_KEY,
        });
        if (loginResult.success) {
          await this.$store.dispatch("auth/refreshUserInfo");
          this.$router.push({ path: "/" });
        } else {
          this.loginError = loginResult.payload;
        }
        this.loginId = null;
      }
    },
  },
};
</script>
<style></style>
